import { FC, KeyboardEvent, ReactNode, useState } from 'react'
import {
  Autocomplete,
  Box,
  ButtonBase,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography
} from '@mui/material'
import { styles } from './Search.style'
import { colors } from '../../theme'
import { FormattedMessage, useIntl } from 'react-intl'
import { FaSearch } from 'react-icons/fa'
import { useSearchFilters } from '../../api/hooks/useSearchFilters'
import { useJobPostingItems } from '../../api/hooks/useJobPostingItems'
import { BsArrowDownUp } from 'react-icons/bs'

export interface SearchQuery {
  search?: string
  region?: string 
  query?: string
  enabled?: boolean
}

export enum SortOrder {
  NEWEST = 'NEWEST',
  OLDEST = 'OLDEST'
}

export interface SearchFilters {
  queries: string[]
  regions: string[]
}

interface SearchProps {
  onSearch: (searchQuery: SearchQuery) => void
  onSortOrderChange: (sortOrder: SortOrder) => void
}

export const Search: FC<SearchProps> = ({ onSearch, onSortOrderChange }: SearchProps) => {
  const intl = useIntl()

  const [searchQuery, setSearchQuery] = useState<SearchQuery>({})
  const [sortOrder, setSortOrder] = useState<SortOrder>(SortOrder.NEWEST)

  const { data: dataJobPostingItems } = useJobPostingItems(searchQuery)
  const { data: dataSearchFilters, isLoading } = useSearchFilters()

  const [search, setSearch] = useState<string | undefined>(undefined)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearch(e.target.value)
  }

  const noRegion = 'Alle'
  const [region, setRegion] = useState<string | undefined>(noRegion)
  const regions = dataSearchFilters?.regions ?? []

  const [education, setEducation] = useState<string | undefined>(undefined)
  const educations = dataSearchFilters?.queries ?? []

  const handleSortOrderChange = (event: SelectChangeEvent<string>) => {
    const newSortOrder = SortOrder[event.target.value as keyof typeof SortOrder]
    setSortOrder(newSortOrder)
    onSortOrderChange(newSortOrder)
  }

  const handleSearch = () => {
    const query = {
      search,
      region: region === noRegion ? undefined : region,
      query: education,
      enabled: true
    }
    setSearchQuery(query)
    onSearch(query)
    document.dispatchEvent(new CustomEvent('tur:search', {
      detail: {
        search,
        region: region ?? noRegion,
        education: education ?? 'Alle',
        page: '1'
      }
    }))
  }

  const emptyQuery = (query: SearchQuery): boolean => {
    return (query.search === undefined || query.search.trim() === '') && 
    (query.region === undefined || query.region === noRegion) && 
    query.query === undefined
  }

  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>): void => {
    if (e.key === 'Enter') {
      handleSearch()
    }
  }

  return (
    <Box sx={styles.root}>
      <Grid container justifyContent='center'>
        <Grid item xs={12} md={11}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Box sx={styles.intro}>
                <Typography variant='h1'>
                  <FormattedMessage id='search.title' />
                </Typography>
                <Box mt={2}>
                  <Typography variant='body1'>
                    <FormattedMessage id='search.description' values={{ br: <br />  }}/>
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Box sx={styles.searchContainer}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={3}>
                <Box pl={2} sx={styles.searchInputContainer}>
                  <Box>
                    <Typography variant='body1' color={colors.ultramarineBlue}>
                      <FormattedMessage id='search.field.search' />
                    </Typography>
                    <Box sx={styles.inputField}>
                      <TextField
                        sx={styles.textField}
                        variant='standard'
                        placeholder={intl.formatMessage({ id: 'search.field.search.placeholder' })}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                        InputProps={{
                          disableUnderline: true
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <Box pl={2} sx={styles.searchInputContainer}>
                  <Box>
                    <Typography variant='body1' color={colors.ultramarineBlue}>
                      <FormattedMessage id='search.field.region' />
                    </Typography>
                    <Box sx={styles.inputField}>
                      <Select
                        sx={styles.selectField}
                        variant='standard'
                        value={region}
                        onChange={event => setRegion(event.target.value)}
                        disableUnderline
                        displayEmpty
                        renderValue={region !== noRegion ? undefined : () => (
                          <Typography variant='body1' color={colors.quickSilver}>
                            {intl.formatMessage({ id: 'search.field.region.placeholder' })}
                          </Typography>
                        )}
                      >
                        {[noRegion, ...regions.sort()].map((r, i) => <MenuItem key={i} value={r}>{r}</MenuItem>)}
                      </Select>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <Box pl={2} sx={styles.searchInputContainer}>
                  <Box>
                    <Typography variant='body1' color={colors.ultramarineBlue}>
                      <FormattedMessage id='search.field.education' />
                    </Typography>
                    <Box sx={styles.inputField}>
                      <Autocomplete
                        fullWidth
                        disablePortal
                        options={isLoading ? [] : educations.sort()}
                        onChange={(event, value) => {
                          setEducation(value == null ? undefined : value)
                        }}
                        sx={styles.autocompleteField}
                        renderInput={(params) => <TextField {...params} placeholder={intl.formatMessage({ id: 'search.field.education.placeholder' })} />}
                      />
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <Box mb={2} pl={2} sx={styles.searchInputContainer}>
                  <Box>
                    <Typography variant='body1' color={colors.ultramarineBlue}>
                      <FormattedMessage id='search.result.sort' />
                    </Typography>
                    <Box sx={styles.inputField}>
                      <Select
                        sx={styles.sortSelectField}
                        variant='standard'
                        value={sortOrder}
                        onChange={handleSortOrderChange}
                        disableUnderline
                        displayEmpty
                        renderValue={() => (
                          <Box>
                            <BsArrowDownUp strokeWidth='0.7' />
                            <Typography ml={1.5} width={100} variant='body1'>
                              {intl.formatMessage({ id: `search.result.sort.${sortOrder}` })}
                            </Typography>
                          </Box>
                        )}
                      >
                        {[...Object.values(SortOrder)].map((order, i) => <MenuItem key={i} value={order}>{intl.formatMessage({ id: `search.result.sort.${order}` })}</MenuItem>)}
                      </Select>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <ButtonBase onClick={handleSearch} sx={{ ...styles.searchButtonWrapper, ...(dataJobPostingItems?.length === undefined && { marginBottom: 3 }) }}>
            <Box sx={styles.searchButton}>
              <Typography mr={1.5} variant='body1' color={colors.white}>
                <FormattedMessage id='search.button' />
              </Typography>
              <FaSearch color={colors.white}/>
            </Box>
          </ButtonBase>
          {dataJobPostingItems?.length !== undefined && (
            <Box mb={3} ml={4} mr={3} sx={styles.searchResult}>
              <Typography variant='body1' component='div'>
                <FormattedMessage
                  id={`search.result.size${emptyQuery(searchQuery) ? '.noquery' : ''}`}
                  values={{
                    b: (((chunks: string[]) => <Typography variant='body1' display='inline'>{chunks}</Typography>) as unknown) as ReactNode,
                    size: dataJobPostingItems?.length
                  }}
                />
              </Typography>
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  )
}

export default Search
