import { FC, useEffect, useMemo, useState } from 'react'
import { Box, CircularProgress, Typography } from '@mui/material'
import { styles } from './SearchResult.style'
import JobPosting, { JobPostingItem } from '../job-postings/JobPosting'
import { FormattedMessage } from 'react-intl'
import { colors } from '../../theme'
import Paginator from '../paginator/Paginator'
import { useJobPostingItems } from '../../api/hooks/useJobPostingItems'
import { SearchQuery, SortOrder } from '../search/Search'

interface SearchResultProps {
  searchQuery: SearchQuery
  sortOrder: SortOrder
}

export const SearchResult: FC<SearchResultProps> = ({ searchQuery, sortOrder }: SearchResultProps) => {
  const [pageCount, setPageCount] = useState<number>(0)
  const [currentPageIndex, setCurrentPageIndex] = useState<number>(0)
  const [pagedItems, setPagedItems] = useState<JobPostingItem[]>([])

  const itemsPerPage = 5

  const { data, isLoading, isError } = useJobPostingItems(searchQuery)
  const items = useMemo(() => {
    if (sortOrder === SortOrder.NEWEST) {
      return data?.sort((a, b) =>  +(b.id ?? '0') - +(a.id ?? '0')) ?? []
    }
    return data?.sort((a, b) =>  +(a.id ?? '0') - +(b.id ?? '0')) ?? []
  }, [data, sortOrder])

  const handlePageChange = (pageIndex: number) => {
    setCurrentPageIndex(pageIndex)
    document.dispatchEvent(new CustomEvent('tur:pageChange', {
      detail: {
        search: searchQuery.search,
        region: searchQuery.region ?? 'Alle',
        education: searchQuery.query ?? 'Alle',
        page: `${pageIndex + 1}`
      }
    }))
  }

  useEffect(() => {
    setPageCount(Math.ceil(items.length / itemsPerPage))
    setCurrentPageIndex(0)
  }, [items])

  useEffect(() => {
    const offset = currentPageIndex * itemsPerPage
    setPagedItems(items.slice(offset, offset + itemsPerPage))
  }, [items, currentPageIndex, pageCount, setPagedItems, sortOrder])

  return (
    <Box
      sx={{
        ...styles.root,
        ...(!data?.length && !isLoading && { padding: 0})
      }}
    >
      {isLoading && (
        <Box sx={styles.progress}>
          <CircularProgress />
        </Box>
      )}
      {isError && (
        <Box>
          <Typography variant='body1' color={colors.maroon}>
            <FormattedMessage id='search.error' />
          </Typography>
        </Box>
      )}
      {!isLoading && !isError && (
        <Box>
          {pageCount > 1 && (
            <Paginator currentPageIndex={currentPageIndex} pageCount={pageCount} onPageChange={handlePageChange} mb={3} />
          )}
          {pagedItems.map((item, index) => (
            <JobPosting key={index} item={item} />
          ))}
          {pageCount > 1 && (
            <Paginator currentPageIndex={currentPageIndex} pageCount={pageCount} onPageChange={handlePageChange} mt={3} />
          )}
        </Box>
      )}
    </Box>
  )
}

export default SearchResult