import { createTheme, SxProps, Theme } from '@mui/material/styles'
import { CSSProperties } from 'react'

declare module '@mui/material/styles' {
  interface TypographyVariants {
    footer: CSSProperties
    footerLink: CSSProperties
    header: CSSProperties
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    footer?: CSSProperties
    footerLink?: CSSProperties
    header?: CSSProperties
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    footer: true
    footerLink: true
    header: true

  }
}

interface Styles {
  [name: string]: SxProps<Theme>
}

export const createStyles = <T extends Styles>(styles: T): T => styles

export const colors = {
  resolutionBlue: '#05238B',
  maximumBluePurple: '#0647C7',
  royalBlue: '#a0b5f4',
  ultramarineBlue: '#4470EF',
  absoluteZeroCrayola: '#0047C7',
  textBase: '#001F4F',
  electricBlue: '#81F2FF',
  quickSilver: '#A6A6A6',
  maroon: '#C01048',
  white: '#FFFFFF',
  black: '#000000'
}

export const theme = createTheme({
  typography: {
    fontFamily: [
      'Roboto',
      'DM Sans',
      'Gill Sans',
      'Gill Sans MT',
      'Calibri',
      'Trebuchet MS',
      'sans-serif'
    ].join(','),
    footer: {
      fontSize: '17 px',
      fontWeight: 400,
      color: colors.royalBlue,
      lineHeight: '25px'
    },
    footerLink: {
      fontSize: '17 px',
      fontWeight: 700,
      color: colors.royalBlue,
      lineHeight: '25px'
    },
    header: {
      fontSize: '17 px',
      fontWeight: 700,
      color: colors.white,
      lineHeight: '25px'
    }
  },
  palette: {
    primary: {
      main: colors.resolutionBlue
    }
  },
  components: {
    // TODO: Maybe define custom ones that match UI style guide
    MuiTypography: {
      styleOverrides: {
        h1: {
          fontSize: 32,
          fontWeight: 700,
          color: colors.resolutionBlue
        },
        h2: {
          fontSize: 28,
          fontWeight: 700,
          color: colors.resolutionBlue
        },
        h3: {
          fontSize: 24,
          fontWeight: 700,
          color: colors.resolutionBlue
        },
        h4: {
          fontSize: 18,
          fontWeight: 700,
          color: colors.resolutionBlue
        },
        h5: {
          fontSize: 16,
          fontWeight: 700,
          color: colors.resolutionBlue
        },
        body1: {
          fontSize: 16,
          fontWeight: 500,
          color: colors.resolutionBlue,
          lineHeight: '18px'
        },
        body2: {
          fontSize: 16,
          fontWeight: 400,
          color: colors.resolutionBlue,
          lineHeight: '18px'
        }
      }
    }
  }
})
