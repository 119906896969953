import { colors, createStyles } from '../../theme'

export const styles = createStyles({
  root: {
    display: 'flex',
    flex: 1,
    width: '100%',
    flexDirection: 'column',
    background: colors.resolutionBlue
  },
  content: {
    display: 'flex',
    flex: '1 0 auto',
    width: '100%',
    flexDirection: 'column'
  }
})
