import { FC } from 'react'
import { Box, ButtonBase, Typography } from '@mui/material'
import { styles } from './Paginator.style'
import { colors } from '../../theme'
import { FormattedMessage } from 'react-intl'
import { MdArrowBackIos, MdArrowForwardIos } from 'react-icons/md'

interface PaginatorProps {
  currentPageIndex: number
  pageCount: number
  onPageChange: (pageIndex: number) => void
  mt?: number
  mb?: number
}

export const Paginator: FC<PaginatorProps> = ({ currentPageIndex, pageCount, onPageChange, mt = 0, mb = 0 }: PaginatorProps) => {

  const isFirstBoxSelected = currentPageIndex === 0
  const isSecondBoxSelected = pageCount <= 2 ? currentPageIndex === pageCount - 1 : (currentPageIndex > 0 && currentPageIndex < (pageCount - 1))
  const isThirdBoxSelected = currentPageIndex === pageCount - 1

  const getPageNumber = (index: number): number => index + 1

  const getFirstIndex = (currentIndex: number): number => {
    if (isFirstBoxSelected) {
      return currentIndex
    } else if (isSecondBoxSelected) {
      return currentIndex - 1
    } else {
      return currentIndex - 2
    }
  }

  const getSecondIndex = (currentIndex: number): number => {
    if (isFirstBoxSelected) {
      return currentIndex + 1
    }else if (isSecondBoxSelected) {
      return currentIndex
    } else {
      return currentIndex - 1
    }
  }

  const getThirdIndex = (currentIndex: number): number => {
    if (isFirstBoxSelected) {
      return currentIndex + 2
    } else if (isSecondBoxSelected) {
      return currentIndex + 1
    } else {
      return currentIndex
    }
  }

  return (
    <Box mt={mt} mb={mb} sx={styles.root}>
      <Box sx={styles.previousButtonWrapper}>
        {currentPageIndex !== 0 && (
          <ButtonBase
            onClick={() => onPageChange(currentPageIndex - 1)}
            sx={styles.button}
          >
            <Box sx={styles.buttonContent}>
              <MdArrowBackIos color={colors.royalBlue} />
              <Typography variant='body1' color={colors.royalBlue} ml={0.5}>
                <FormattedMessage id='paginator.previous' />
              </Typography>
            </Box>
          </ButtonBase>
        )}
      </Box>
      <ButtonBase
        sx={styles.numberButton}
        onClick={() => onPageChange(getFirstIndex(currentPageIndex))}
        disabled={isFirstBoxSelected}
      >
        <Box sx={isFirstBoxSelected ? styles.slected : styles.notSelected}>
          <Typography variant='body1' color={isFirstBoxSelected ? colors.white : colors.resolutionBlue}>
            {getPageNumber(getFirstIndex(currentPageIndex))}
          </Typography>
        </Box>
      </ButtonBase>
      <ButtonBase
        sx={styles.numberButton}
        onClick={() => onPageChange(getSecondIndex(currentPageIndex))}
        disabled={isSecondBoxSelected}
      >
        <Box sx={isSecondBoxSelected ? styles.slected : styles.notSelected}>
          <Typography variant='body1' color={isSecondBoxSelected ? colors.white : colors.resolutionBlue}>
            {getPageNumber(getSecondIndex(currentPageIndex))}
          </Typography>
        </Box>
      </ButtonBase>
      {pageCount > 2 && (
        <ButtonBase
          sx={styles.numberButton}
          onClick={() => onPageChange(getThirdIndex(currentPageIndex))}
          disabled={isThirdBoxSelected}
        >
          <Box sx={isThirdBoxSelected ? styles.slected : styles.notSelected}>
            <Typography variant='body1' color={isThirdBoxSelected ? colors.white : colors.resolutionBlue}>
              {getPageNumber(getThirdIndex(currentPageIndex))}
            </Typography>
          </Box>
        </ButtonBase>
      )}
      <Box sx={styles.nextButtonWrapper}>
        {currentPageIndex < pageCount - 1 && (
          <ButtonBase
            onClick={() => onPageChange(currentPageIndex + 1)}
            sx={styles.button}
          >
            <Box sx={styles.buttonContent}>
              <Typography variant='body1' color={colors.royalBlue} mr={0.5}>
                <FormattedMessage id='paginator.next' />
              </Typography>
              <MdArrowForwardIos color={colors.royalBlue} />
            </Box>
          </ButtonBase>
        )}
      </Box>
    </Box>
  )
}

export default Paginator
