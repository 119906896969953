import { colors, createStyles } from '../../theme'

export const styles = createStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    height: 100,
    paddingX: 2,
    background: colors.resolutionBlue
  }
})
