import { FC } from 'react'
import { IntlProvider } from 'react-intl'
import { createTheme, ThemeProvider } from '@mui/material'
import { ApiProvider } from './api/ApiProvider'
import { theme } from './theme'
import FrontPage from './components/frontpage/FrontPage'
import messages_da from './translations/da.json'
import messages_en from './translations/en.json'

const locale = 'da'
const messages = {
  da: messages_da,
  en: messages_en
}

const App: FC = () => {
  const apiUrl = 'https://test.alexandra.dk/api'

  return (
    <IntlProvider locale={locale} key={locale} messages={messages[locale]}>
      <ThemeProvider theme={createTheme(theme)}>
        <ApiProvider apiUrl={apiUrl}>
          <FrontPage />
        </ApiProvider>
      </ThemeProvider>
    </IntlProvider>
  )
}

export default App
