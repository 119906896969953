import { FC, useState } from 'react'
import { Box } from '@mui/material'
import { styles } from './FrontPage.style'
import Header from '../header/Header'
import Search, { SearchQuery, SortOrder } from '../search/Search'
import SearchResult from '../search-result/SearchResult'
import Footer from '../footer/Footer'

export const FrontPage: FC = () => {
  const [searchQuery, setSearchQuery] = useState<SearchQuery>({})
  const [sortOrder, setSortOrder] = useState<SortOrder>(SortOrder.NEWEST)
  
  return (
    <Box sx={styles.root}>
      <Header />
      <Box sx={styles.content}>
        <Search onSearch={setSearchQuery} onSortOrderChange={setSortOrder} />
        <SearchResult searchQuery={searchQuery} sortOrder={sortOrder} />
      </Box>
      <Footer />
    </Box>
  )
}

export default FrontPage
