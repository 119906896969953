import { createContext, ReactNode, useContext } from 'react'
import { useQuery, UseQueryResult } from 'react-query'
import { JobPostingItem } from '../components/job-postings/JobPosting'
import { SearchFilters, SearchQuery } from '../components/search/Search'

/**
 * ApiType specifies the available type of api calls that can be used from the provider.
 */
export interface ApiType {
  useSearchFiltersQuery: () => UseQueryResult<SearchFilters, Error>
  useJobPostingItemsQuery: (searchQuery: SearchQuery) => UseQueryResult<JobPostingItem[], Error>
}

/**
 * ApiProviderProps specifies props that can be passed to the provider.
 */
interface ApiProviderProps {
  children?: ReactNode
  apiUrl: string
}

export interface TestData {
  text: string
}

// TODO: Should be configurable by environment variable
const baseUrl = 'https://freshrss.portal.transportuddannelser.dk/i/?c=wsg&'

// TODO: Fix types, avoid undefined! initial value?
// https://medium.com/@rivoltafilippo/typing-react-context-to-avoid-an-undefined-default-value-2c7c5a7d5947
// eslint-disable-next-line
const ApiContext = createContext<ApiType>(undefined!)

/**
 * Exposes useApi handle that can be used in components to make call to the api.
 * @returns ApiType object.
 */
export const useApi = (): ApiType => {
  return useContext(ApiContext)
}

/**
 * ApiProvider provides access to various queries and actions on the BFF.
 * @param param0 Children and api url.
 * @returns ApiProvider
 */
export const ApiProvider = ({
  children,
  apiUrl
}: ApiProviderProps): JSX.Element => {

  const buildSearchQuery = (searchQuery: SearchQuery): string => {
    let query = ''
    if (searchQuery.search) {
      query = `${query}&text=${searchQuery.search}`
    }
    if (searchQuery.region) {
      query = `${query}&regions[]=&regions[]=${searchQuery.region}`
    }
    if (searchQuery.query) {
      query = `${query}&queries[]=${searchQuery.query}`
    }
    return query.replaceAll(' ', '+')
  }

  const getSearchFilters = async (): Promise<SearchFilters> => {
    try {
      const res = await fetch(`${baseUrl}a=criteria`)
      const data = await res.json()
      return new Promise<SearchFilters>(resolve => resolve(data as SearchFilters))
    } catch (error) {
      console.error(error)
    }
    return new Promise<SearchFilters>(resolve => resolve({ queries: [], regions: [] }))
  }

  const getJobPostings = async (searchQuery: SearchQuery): Promise<JobPostingItem[]> => {
    try {
      const res = await fetch(`${baseUrl}a=jobs&nb=99999${buildSearchQuery(searchQuery)}`)
      const data = await res.json()
      return new Promise<JobPostingItem[]>(resolve => resolve(data as JobPostingItem[]))
    } catch (error) {
      console.error(error)
    }
    return new Promise<JobPostingItem[]>(resolve => resolve([]))
  }

  const useSearchFiltersQuery = (): UseQueryResult<SearchFilters, Error> =>
    useQuery<SearchFilters, Error>(['searchFilters'], async () => getSearchFilters(), {
      staleTime: 1000 * 60 * 10
    })

  const useJobPostingItemsQuery = (searchQuery: SearchQuery): UseQueryResult<JobPostingItem[], Error> =>
    useQuery<JobPostingItem[], Error>(['jobPostings', searchQuery], async () => getJobPostings(searchQuery),{
      staleTime: 1000 * 60 * 10,
      enabled: searchQuery.enabled === true
    })

  return (
    <ApiContext.Provider
      value={{
        useSearchFiltersQuery,
        useJobPostingItemsQuery
      }}
    >
      {children}
    </ApiContext.Provider>
  )
}
