import { colors, createStyles } from '../../theme'

export const styles = createStyles({
  rootContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  rootGridItem: {
    display: 'flex',
    justifyContent: 'center'
  },
  root: {
    width: '100%',
    maxWidth: 1000,
    marginBottom: 3
  },
  paper: {
    padding: 4,
    background: colors.white,
    borderStyle: 'solid',
    borderWidth: 2,
    borderColor: colors.resolutionBlue,
    boxShadow: `8px 8px 0px -1px ${colors.absoluteZeroCrayola}`
  },
  description: {
    width: '100%',
    marginTop: 2
  },
  navigateButton: {
    display: 'flex',
    alignItems: 'center',
    paddingY: 1.5,
    paddingX: 5,
    background: colors.absoluteZeroCrayola,
    boxShadow: `5px 5px 0px -1px ${colors.electricBlue}`
  },
  navigateButtonGrid: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  breakWord: {
    overflowWrap: 'break-word'
  },
  ellipsis: {
    width: '90%',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  }
})
