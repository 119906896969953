import { colors, createStyles } from '../../theme'

export const styles = createStyles({
  root: {
    padding: 3,
    background: colors.maximumBluePurple
  },
  progress: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%'
  }
})
