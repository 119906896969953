import { FC } from 'react'
import { Box, Grid, Link, Typography } from '@mui/material'
import { styles } from './Footer.style'
import { FacebookIcon, HeaderIcon, InstagramIcon, YoutubeIcon } from '../../icons'
import { FormattedMessage } from 'react-intl'

export const Footer: FC = () => {
  return (
    <Box sx={styles.root}>
      <Grid mx={2} container justifyContent='center' alignItems='center'>
        <Grid item xs={12} lg={0}>
          <Box sx={styles.logoMobile}>
            <Link href='https://transportuddannelser.dk/' target='_blank'>
              <HeaderIcon height={120} width={300} />
            </Link>
          </Box>
        </Grid>
        <Grid item xs={12} lg={4} alignItems='end'>
          <Box sx={styles.info}>
            <Box sx={styles.infoContent}>
              <Link href='https://tur.dk/' target='_blank'>
                <Typography variant='footer'>
                  <FormattedMessage id='footer.info.company' />
                </Typography>
              </Link>
              <Link href='https://goo.gl/maps/JqL5GgSBTXEJhou9A' target='_blank'>
                <Typography variant='footer'>
                  <FormattedMessage id='footer.info.address' />
                </Typography>
              </Link>
              <Link href='https://goo.gl/maps/JqL5GgSBTXEJhou9A' target='_blank'>
                <Typography variant='footer'>
                  <FormattedMessage id='footer.info.city' />
                </Typography>
              </Link>
            </Box>
            <Box mt={2.5} sx={styles.infoContent}>
              <Link href='mailto:tur@tur.dk'>
                <Typography variant='footer'>
                  <FormattedMessage id='footer.info.email' />
                </Typography>
              </Link>
              <Link href='tel:35878700'>
                <Typography variant='footer'>
                  <FormattedMessage id='footer.info.phone' />
                </Typography>
              </Link>
              <Typography variant='footer'>
                <FormattedMessage id='footer.info.cvr' />
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={0} lg={4}>
          <Box ml={6} sx={styles.logo}>
            <Link href='https://transportuddannelser.dk/' target='_blank'>
              <HeaderIcon height={140} width={350} />
            </Link>
          </Box>
        </Grid>
        <Grid item xs={12} lg={4} sx={styles.linksGrid}>
          <Box sx={styles.links}>
            <Box sx={styles.linksContent}>
              <Box mb={3}>
                <Link href='https://www.facebook.com/MinTransportuddannelse' target='_blank'>
                  <FacebookIcon height={40} width={40} />
                </Link>
                <Link ml={1} href='https://www.youtube.com/channel/UC8-e8wuZVKH0LRzOO9hqvFQ/featured' target='_blank'>
                  <YoutubeIcon height={40} width={40} />
                </Link>
                <Link ml={1} href='https://www.instagram.com/mintransportuddannelse/' target='_blank'>
                  <InstagramIcon height={40} width={40} />
                </Link>
              </Box>
              <Link href='https://gdpr.tur.dk/' target='_blank'>
                <Typography variant='footerLink'>
                  <FormattedMessage id='footer.link.cookies' />
                </Typography>
              </Link>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Footer
