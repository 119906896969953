import { FC } from 'react'
import { Box, ButtonBase, Grid, Link, Typography } from '@mui/material'
import { styles } from './JobPosting.style'
import { colors } from '../../theme'
import { FormattedMessage } from 'react-intl'
import { MdArrowForwardIos } from 'react-icons/md'

export interface JobPostingItem {
  link?: string
  via?: string
  title?: string
  company?: string
  location?: string
  region?: string
  snippet?: string
  id?: string
}

interface JobPostingProps {
  item: JobPostingItem
}

export const JobPosting: FC<JobPostingProps> = ({ item }: JobPostingProps) => {
  return (
    <Grid container spacing={2} sx={styles.rootContainer}>
      <Grid item xs={12} md={8} sx={styles.rootGridItem}>
        <Box sx={styles.root}>
          <Box sx={styles.paper}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={8} lg={7}>
                <Typography variant='h2' sx={styles.breakWord}>
                  {item.title}
                </Typography>
                <Box mt={2}>
                  {item.location !== undefined && (
                    <Typography variant='h4' fontSize={14}>
                      {item.location}
                    </Typography>
                  )}
                  <Typography variant='h4'>
                    {item.company}
                  </Typography>
                </Box>
                <Box sx={styles.description}>
                  <Typography variant='body1'>
                    {item.snippet}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={4} lg={5} sx={styles.navigateButtonGrid}>
                <ButtonBase
                  onClick={() => window.open(item.link, "_blank")}
                >
                  <Box sx={styles.navigateButton}>
                    <Typography mr={1.5} variant='body1' color={colors.white}>
                      <FormattedMessage id='jobposting.button' />
                    </Typography>
                    <MdArrowForwardIos color={colors.white} />
                  </Box>
                </ButtonBase>
              </Grid>
            </Grid>
            {item.link !== undefined && (
              <Grid container mt={3} spacing={0}>
                <Grid item width={165}>
                  <Typography variant='body1'>
                    <FormattedMessage id='jobposting.link' />
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Typography variant='body2' sx={styles.ellipsis}>
                    <Link href={item.link} target='_blank' color={colors.absoluteZeroCrayola}>{item.link}</Link>
                  </Typography>
                </Grid>
              </Grid>
            )}
            {item.via !== undefined && (
              <Grid container mt={0.5} spacing={0}>
                <Grid item width={35}>
                  <Typography variant='body1'>
                    <FormattedMessage id='jobposting.via' />
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Typography variant='body2' sx={styles.ellipsis}>
                    <Link href={item.via} target='_blank' color={colors.absoluteZeroCrayola}>{item.via}</Link>
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}

export default JobPosting
