import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { v4 as uuid } from 'uuid'

export interface AuthState {
  userId: string
}

export const authInitialState: AuthState = {
  userId: uuid()
}

export const authSlice = createSlice({
  name: 'auth',
  initialState: authInitialState,
  reducers: {
    setUserId: (state: AuthState, action: PayloadAction<string | undefined>) => {
      state.userId = action.payload !== undefined ? action.payload : ''
    }
  }
})

// Action creators are generated for each case reducer function
export const authActions = authSlice.actions
export default authSlice.reducer
