import { colors, createStyles } from '../../theme'

const notSelected = {
  display: 'flex',
  justifyContent: 'center',
  background: colors.white, // background color for not selected boxes
  alignItems: 'center',
  height: 25,
  width: 25,
  border: 'solid',
  borderWidth: 1,
  borderColor: colors.white // border color
}

const buttonWrapper = {
  display: 'flex',
  width: 110
}

export const styles = createStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%'
  },
  button: {
    paddingY: 1,
    paddingX: 2
  },
  numberButton: {
    marginX: 0.5
  },
  notSelected,
  slected: {
    ...notSelected,
    background: colors.resolutionBlue, // selected box background color
    borderColor: colors.resolutionBlue
  },
  nextButtonWrapper: {
    ...buttonWrapper,
    justifyContent: 'start'
  },
  previousButtonWrapper: {
    ...buttonWrapper,
    justifyContent: 'end'
  },
  buttonContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  }
})
