import { colors, createStyles, theme } from '../../theme'

export const styles = createStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginY: 4,
    background: colors.resolutionBlue
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('lg')]: {
      marginY: 4,
    }
  },
  infoContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
    [theme.breakpoints.down('lg')]: {
      alignItems: 'center',
    },
    '& :hover': {
      color: colors.white,
      textDecoration: 'none'
    }
  },
  linksGrid: {
    '&.MuiGrid-root': {
      [theme.breakpoints.up('lg')]: {
        height: '100%'
      }
    }
  },
  links: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'end',
    alignItems: 'center',
    height: '100%'
  },
  linksContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
    [theme.breakpoints.down('lg')]: {
      alignItems: 'center',
    },
    '& :hover': {
      color: colors.white,
      textDecoration: 'none'
    }
  },
  logo: {
    [theme.breakpoints.down('lg')]: {
      display: 'none'
    }
  },
  logoMobile: {
    [theme.breakpoints.up('lg')]: {
      display: 'none'
    },
    [theme.breakpoints.down('lg')]: {
      display: 'flex',
      justifyContent: 'center'
    }
  }
})
