import { colors, createStyles } from '../../theme'

const textField = {
  display: 'flex',
  flex: 1,
  marginX: 3,
  '& input': {
    color: colors.resolutionBlue
  }
}

export const styles = createStyles({
  root: {
    paddingTop: 3,
    background: colors.white
  },
  intro: {
    paddingX: 4,
    wordBreak: 'break-word'
  },
  searchContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: 3,
    marginTop: 1,
    marginBottom: 0,
    marginLeft: 1
  },
  searchInputContainer: {
    marginTop: 2
  },
  searchResult: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'end'
  },
  inputField: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
    width: '100%',
    marginTop: 1,
    borderStyle: 'solid',
    borderWidth: 2,
    borderColor: colors.absoluteZeroCrayola,
    boxShadow: `5px 5px 0px -1px ${colors.absoluteZeroCrayola}`
  },
  textField,
  autocompleteField: {
    paddingLeft: 1,
    paddingRight: 2,
    '&.MuiAutocomplete-root': {
      '& fieldset': {
        border: 'none'
      },
      '& svg': {
        color: colors.resolutionBlue
      },
      '& .MuiAutocomplete-input': {
        color: colors.resolutionBlue
      }
    }
  },
  selectField: {
    ...textField,
    '&.MuiInput-root': {
      '& div': {
        display: 'flex',
        alignItems: 'center'
      },
      '& svg': {
        color: colors.resolutionBlue
      },
      '& .MuiSelect-select': {
        color: colors.resolutionBlue,
        '& p': {
          color: `${colors.maximumBluePurple}79`
        }
      }
    }
  },
  sortSelectField: {
    ...textField,
    '&.MuiInput-root': {
      '& div': {
        display: 'flex',
        alignItems: 'center'
      },
      '& svg': {
        color: colors.resolutionBlue
      },
      '& .MuiSelect-select': {
        color: colors.resolutionBlue,
        '& p': {
          color: colors.resolutionBlue
        }
      }
    }
  },
  searchButton: {
    display: 'flex',
    alignItems: 'center',
    paddingY: 1.5,
    paddingX: 5,
    background: colors.absoluteZeroCrayola,
    boxShadow: `5px 5px 0px -1px ${colors.electricBlue}`
  },
  searchButtonWrapper: {
    marginBottom: 2,
    marginLeft: 3
  }
})
