import { FC } from 'react'
import { Box, Grid, Link, Typography } from '@mui/material'
import { styles } from './Header.style'
import { HeaderIcon } from '../../icons'
import { FormattedMessage } from 'react-intl'

export const Header: FC = () => {
  return (
    <Box sx={styles.root}>
      <Grid container justifyContent='center'>
        <Grid item xs={12} md={11.2}>
          <Link href='https://transportuddannelser.dk/' target='_blank'>
            <HeaderIcon height={70} width={175} />
          </Link>
        </Grid>
      </Grid>
      <Grid container justifyContent='right'>
        <Grid item md={3.2} >
          <Link href='https://transportuddannelser.dk/' target='_blank'>
            <Typography variant='header'>
              <FormattedMessage id='header.return' />
            </Typography>
          </Link>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Header
